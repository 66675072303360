import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { StandardSingleColumn } from "../../layouts/Containers"

const releaseNotesLayoutCss: ThemeCss = theme => ({
  width: "100%",

  [theme.mediaQueries.phablet]: {
    width: "680px",
  },
})

export interface ComponentWithChildren {
  children: React.ReactNode
}

export const ReleaseNotesLayout = ({ children }: ComponentWithChildren) => {
  return (
    <StandardSingleColumn>
      <div css={releaseNotesLayoutCss}>{children}</div>
    </StandardSingleColumn>
  )
}

const releaseNoteItemCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",
  padding: `${theme.space[5]} 0`,
  borderBottom: `1px solid ${theme.colors.grey[20]}`,

  [theme.mediaQueries.phablet]: {
    flexDirection: "row",
  },
})

export const ReleaseNotesItem = ({ children }: ComponentWithChildren) => {
  return <div css={releaseNoteItemCss}>{children}</div>
}

const releaseNoteDateCss: ThemeCss = theme => ({
  flex: 1,
})

export const ReleaseNoteDate = ({ children }: ComponentWithChildren) => (
  <div css={releaseNoteDateCss}>{children}</div>
)

const releaseContentCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  flex: 3,
})

export const ReleaseNoteContent = ({ children }: ComponentWithChildren) => (
  <div css={releaseContentCss}>{children}</div>
)

const releaseNoteHeaderCss: ThemeCss = theme => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  [theme.mediaQueries.phablet]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
})

export const ReleaseNoteHeader = ({ children }: ComponentWithChildren) => (
  <div css={releaseNoteHeaderCss}>{children}</div>
)

export const releaseNoteTitleCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[4],
  margin: 0,
  marginBottom: theme.space[3],
  padding: 0,

  [theme.mediaQueries.phablet]: {
    marginBottom: 0,
  },
})

export const releaseNoteHtmlCss: ThemeCss = theme => ({
  // Global selectors to target the inner html content from WordPress
  ul: {
    marginTop: theme.space[3],
  },
  li: {
    margin: 0,
    marginBottom: theme.space[1],
    padding: 0,
  },
})

export const navCss: ThemeCss = theme => ({
  height: theme.space[11],
  display: "flex",
  alignItems: "center",
})
